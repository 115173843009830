<template>
  <div class="flex items-center">
    {{ getFournisseurById ? getFournisseurById.Nom : '' }}
  </div>
</template>

<script>
export default {
  name: 'CellRendererFournisseur',
  props: ['IdFour'],
  computed: {
    fournisseurs () {
      return this.$store.state.fournisseur.fournisseurs
    },
    getFournisseurById () {
      return this.$store.getters['fournisseur/getFournisseurById'](this.IdFour)
    }
  },
  methods: {
    getFournisseurs () {
      this.$store.dispatch('fournisseur/getFournisseurs').catch(err => { console.error(err) })
    }
  },
  created () {
    this.getFournisseurs()
  }
}
</script>
